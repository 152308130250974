import React, { useMemo } from 'react'

import { NpmFAQPage } from '../local_modules'
import { HAS_WINDOW } from '../local_modules/constants'

const Attendance = () => {
  const { searchParam, pathname } = useMemo(() => {
    if (!HAS_WINDOW) {
      return {}
    }

    const initialSearchParam = window.location.search
    const initialPathname = window.location.pathname

    return {
      searchParam: initialSearchParam,
      pathname: initialPathname,
    }
  }, [HAS_WINDOW])

  return <NpmFAQPage pathName={pathname} searchParam={searchParam} />
}

export default Attendance
